import { useState, useEffect } from 'react';
import * as styled from './Modal.styled'

function Modal({lesson, onClose, joinedLesson, isAdmin}) {
   const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  if (!lesson) return null;

  return (
    <styled.Overlay isVisible={isVisible} onClick={onClose}>
      <styled.ModalContent
        isVisible={isVisible}
        onClick={(e) => e.stopPropagation()}
      >
       
            <styled.CloseButton onClick={onClose}>&times;</styled.CloseButton>
            <styled.studentInfo>Тип урока:  {lesson.type} </styled.studentInfo>
            <styled.studentInfo>Ім'я:  {lesson.name} </styled.studentInfo>
            <styled.studentInfo>ID:  {lesson.studentId} </styled.studentInfo>
            <styled.studentInfo>Час: {lesson.time}</styled.studentInfo>
            <styled.studentInfo>Вікова група: {lesson.ageGroup}</styled.studentInfo>
            <styled.studentInfo>Рівень знань: {lesson.englishLevel}</styled.studentInfo>
            <a href={lesson.googleMeetLink} target="_blank" rel="noopener noreferrer" onClick={() => joinedLesson()}>
              <styled.JoinButton>Розпочати урок</styled.JoinButton>
            </a>
         
       
      </styled.ModalContent>
    </styled.Overlay>
  );
};

export default Modal