import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import ScheduleGrid from "../../../components/ScheduleGrid/ScheduleGrid";
import Modal from "../../../components/Modal/Modal";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";
import useAuthData from "../../../hooks/useAuthData";
import Loader from "../../../components/Loader/Loader";
import {
  daysOfWeek,
  processLessons,
} from "../../../helpers/teacherSchedule/teacherSchedule";
import axios from "../../../axios";

function Schedule() {
  const dispatch = useDispatch();
  const data = useAuthData(dispatch);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [reschedules, setReschedules] = useState([]);
  const joinedLesson = () => {
    
    axios.post("teacher/joined-lesson", {
      lessonData: {
        teacherName: data.name,
        studentName: selectedLesson.name,
        studentId: selectedLesson.studentId,
        lessonTime: selectedLesson.time,
      },
    });

  };
  useEffect(() => {
    if(!data) return
    console.log(data)
    axios.get(`lesson-reschedule/${data._id}`)
    .then(res => setReschedules(res.data.lesson))
    .catch(err => console.error(err))
  },[data])
  if (!data || !data.students || !data.trialLessons || !reschedules) {
    return <Loader />;
  }

  const allLessons = [
    ...processLessons(data.students, "Regular", data.googleMeetLink),
    ...processLessons(data.trialLessons, "Trial", data.googleMeetLink),
    ...processLessons(reschedules, "Reschedule", data.googleMeetLink),
  ];

  const handleLessonClick = (lesson) => {
    setSelectedLesson(lesson);
  };

  const handleCloseModal = () => {
    setSelectedLesson(null);
  };

  return (
    <PagesWrapper>
      <TopPageTitle value={"Графік"} />
      <ScheduleGrid
        daysOfWeek={daysOfWeek}
        lessons={allLessons}
        handleLessonClick={handleLessonClick}
      />
      {selectedLesson && (
        <Modal
          lesson={selectedLesson}
          onClose={handleCloseModal}
          joinedLesson={joinedLesson}
        />
      )}
    </PagesWrapper>
  );
}

export default Schedule;
