import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  ${({ isVisible }) => isVisible && `
    opacity: 1;
  `}
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  transform: translateY(-50px);
  transition: transform 0.3s ease-in-out;
  ${({ isVisible }) => isVisible && `
    transform: translateY(0);
  `}
`;

export const JoinButton = styled.button`
  
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  background: #A09EFF;
  transition: 0.3s;
  font-family: Raleway;
  font-weight: 500;
  &:hover {
    opacity: 0.9;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;

export const studentInfo = styled.p`
   font-family: Raleway;
   font-weight: 500;
   text-align: left;
   &:not(:last-child){
      margin-bottom:20px;
   }
`