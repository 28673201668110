export const adviceTeacher = [
   {
     boldTitle: "Індивідуальний підхід:",
     text: "Враховуйте індивідуальні потреби та рівень кожного студента. Регулярно проводьте оцінку їхнього прогресу та надавайте персоналізовані рекомендації.",
   },
   {
     boldTitle: "Зворотній зв'язок:",
     text: "Регулярно надавайте студентам конструктивний зворотній зв'язок. Вказуйте на їхні успіхи та області, де вони можуть покращити свої навички.",
   },
   {
     boldTitle: "Мотивація та підтримка:",
     text: "Заохочуйте студентів досягати своїх цілей та підтримуйте їх на кожному етапі навчання. Позитивна атмосфера сприяє кращому засвоєнню матеріалу.",
   },
   {
     boldTitle: "Постійне вдосконалення:",
     text: "Слідкуйте за новими методиками та підходами у викладанні мови. Підвищуйте свою кваліфікацію через тренінги, курси та участь у професійних спільнотах.",
   },
];

export const rulesTeacher = [
   {
      boldTitle: "",
      text: "Пробні та регулярні уроки проводяться з увімкненою камерою",
    },
    {
      boldTitle: "",
      text: "Якщо учень не з'явився на урок протягом перших 5 хвилин, вчитель  повинен написати своєму менеджеру",
    },
    {
      boldTitle: "",
      text: "Якщо учень не з'явився на пробному уроці, вчитель має повідомити про це менеджеру та може виходити через 15хв від початку уроку.",
    },
    {
      boldTitle: "",
      text: "Якщо вчитель хоче відмінити урок, необхідно повідомити менеджера мінімум за добу до уроку. Виключення: неочікувані зміни стану здоров’я, але в будь якому випадку необхідно повідомити про це менеджеру. ",
    },
    {
      boldTitle: "",
      text: "Якщо вчитель не з’явився на урок без попередження - штраф 150грн",
    },
    {
      boldTitle: "",
      text: "Вчитель не має права використовувати матеріали школи ‘EnglishLoom’ в своїх цілях: проводити уроки з учнями НЕ нашої школи, використовуючи наші матеріали. ",
    },
    {
      boldTitle: "",
      text: "Забороняється відміняти, переносити уроки не повідомивши менеджера.",
    },
    {
      boldTitle: "",
      text: "Забороняється проводити, назначати додаткові уроки не повідомивши менеджера.",
    },
    {
      boldTitle: "",
      text: "Про відпустку/звільнення попереджати щонайменше за два тижденя",
    },
    {
      boldTitle: "",
      text: "Тривалість уроків: Пробний урок - 30хв, Діти - 45хв, Підлітки - 50хв, Дорослі - 50хв.",
    },
]

export const newFeatureTitleTeacher = "Червоний, якщо скасування 🔴"
export const newFeatureTeacher = [
  {number: '', text: 'Картка червоного кольору з‘явиться у вашому розкладі після скасування уроку.'},
  {number: '', text: 'Через тиждень картка знову стане фіолетовою, адже скасування є разовим.'},
]

export const perfectTeacher = [
  {
    title: "Trial / Regular lesson", 
    link: "https://www.canva.com/design/DAGPcgkxRbk/wHC-hDt1is8ljFvVvzE99g/view?utm_content=DAGPcgkxRbk&utm_campaign=designshare&utm_medium=link&utm_source=editor"
  }
]